class Chat {
    constructor(core, options) {
        this.core = core;
        this.socket = null;
        this.currentRoom = null;
        this.isTrial = false;
        this.initialized = false;   // have we received join:parlay from server?
        this.sortByName = true;
        this.autoOpen = false;

        this.currentPlayerData = null;
        this.players = [];
        this.rooms = [];
        this.messages = [];

        this.signal_chatAction = new Phaser.Signal();

        const pathNoSlash = (window.location.pathname !== '/') ? window.location.pathname : '';
        this.socket = io.connect({path: pathNoSlash + '/chat/socket.io', transports: ["websocket"]});

        this.socket.on('connect', () => {

            this.socket.emit('connect:parlay', {
                appConfig: {
                    configID: options.configID,
                    siteID: options.siteID,
                    style: null,
                    dtlID: options.dtlID,
                    chatPoweredBy: 'bingo',
                },
                userData: {
                    id: options.userId,
                    number: options.sessionId
                }
            });

            return console.log('chat: connect');
        });

        this.socket.on('disconnect:parlay', (data) => {
            console.log('chat: disconnect', data);
            this.signal_chatAction.dispatch({action: "disconnect", message: data});
        });

        this.socket.on('reconnect', () => {
            return console.log('chat: reconnect');
        });

        this.socket.on('connecting', () => {
            return console.log('chat: connecting');
        });

        this.socket.on('reconnecting', () => {
            return console.log('chat: reconnecting');
        });

        this.socket.on('connect_error', () => {
            return console.log('chat: connect failed');
        });

        this.socket.on('reconnect_error', () => {
            return console.log('chat: reconnect failed');
        });

        this.socket.on('close', () => {
            return console.log('chat: close');
        });

        this.socket.on('disconnect', () => {
            return console.log('chat: disconnect');
        });

        this.socket.on('stealth:parlay', (data) => {
            console.log('chat: stealth:parlay', data);
        });

        this.socket.on('mute:parlay', (data) => {
            console.log('chat: mute:parlay', data);

            let message = {
                message: this.core.i18n._t('chatModal.mute.youAreMuted').toString(),
                isSystemMsg: true
            };

            this.messages.push(message);
            this.signal_chatAction.dispatch({action: "mute", message: message});
        });

        this.socket.on('unmute:parlay', (data) => {
            console.log('chat: unmute:parlay', data);

            let message = {
                message: this.core.i18n._t('chatModal.unmute.youAreUnMuted').toString(),
                isSystemMsg: true
            };

            this.messages.push(message);
            this.signal_chatAction.dispatch({action: "unmute", message: message});
        });

        this.socket.on('join:parlay', (data) => {
            this.initialized = true;
            console.log('chat: join:parlay', data);

            this.isTrial = data.trial;

            this.players = [];
            this.rooms = [];
            this.messages = [];

            // let fudgeUID = 654321;
            // _.each(['foo', 'bar','baz','aba','daba','doo','ping','pong','yes','no','maybe','sooo','many','people',
            //     'imay','needto','create','andrea','deirdre', 'timmmm', 'david123', 'aaaaaaaaaaaaaaaaaaaaaaaaaace', 'erma123', 'fatman', 'littleboy',
            // 'thresher', 'kilo', 'enterprise'], function(name) {
            //     data.players.push({ name: name, id: 'FAKE' + (fudgeUID++), level: 1, isModerator: false });
            // });

            _.each(data.players, (player) => {
                let newChatPlayer = {
                    name: player.name,
                    id: player.id,
                    level: player.level,
                    isModerator: player.cm,
                    isMuted: player.muted
                };
                this.players.push(newChatPlayer);

                // store the current user values
                if(newChatPlayer.id === options.userId) {
                    this.currentPlayerData = newChatPlayer;
                }
            });

            Chat.playerSort(this.players);

            _.each(data.rooms, (room) => {
                this.rooms.push({
                    id: room.id,
                    name: room.name,
                    size: room.size,
                    isOpen: room.defaultOpen
                });

                if (!this.currentRoom && room.defaultOpen) {
                    this.currentRoom = room.id;
                }
            });

            // if all rooms were loaded as `defaultOpen = false`. Just pick first as init room
            if(!this.currentRoom && _.size(this.rooms) > 0) {
                this.rooms[0].isOpen = true;
                roomId = this.rooms[0].id;
            }

            let message = null;
            if (data.msg && data.msg.length > 1) {
                message = {
                    message: data.msg,
                    isSystemMsg: false
                };
                this.messages.push(message);
            }

            if(data.autoOpen) {
                this.autoOpen = data.autoOpen;
            }

            this.signal_chatAction.dispatch({action: 'initialized'});
            this.signal_chatAction.dispatch({action: "join", message: message});

            // Inform the user if they are muted (after the join message)
            if(this.currentPlayerData.isMuted) {
                let message = {
                    message: this.core.i18n._t('chatModal.info.youAreMuted').toString(),
                    isSystemMsg: true
                };
                this.messages.push(message);
                this.signal_chatAction.dispatch({action: "info", message: message});
            }
        });

        this.socket.on('info:parlay', (data) => {
            console.log('chat: info:parlay', data);
            let messageObj = null;

            if(data.message && data.message.length !== 0) {
                messageObj = {
                    message: data.message,
                    isSystemMsg: true
                };
                this.messages.push(messageObj);
            }
            this.signal_chatAction.dispatch({action: "info", message: messageObj});
        });

        this.socket.on('disconnect:parlay', (data) => {
            this.signal_chatAction.dispatch({
                action: "disconnect",
                data: data,
                isBan: data.message.includes('banned'),
                isEjected: data.message.includes('ejected')
            });
        });

        this.socket.on('enter:parlay', (data) => {
            console.log('chat: enter:parlay', data);

            let playerExists = false;
            for (let i = 0; i < this.players.length; i++) {
                if (this.players[i].id === data.player.id) {
                    playerExists = true;
                    break;
                }
            }

            if (!playerExists) {
                this.players.push({
                    name: data.player.name,
                    id: data.player.id,
                    level: data.player.level,
                    isModerator: data.player.cm
                });
                Chat.playerSort(this.players);
            }

            if (data.message.length > 1) {
                this.messages.push({
                    message: data.message,
                    isSystemMsg: true,
                });
            }

            this.signal_chatAction.dispatch({action: "enter"});
        });

        this.socket.on('leave:parlay', (data) => {
            console.log('chat: leave:parlay', data);

            for (let i = 0; i < this.players.length; i++) {
                if (this.players[i].id === data.playerId) {
                    this.players.splice(i, 1);
                    Chat.playerSort(this.players);
                    break;
                }
            }

            let msg = null;
            if (data.message.length > 1) {
                msg = {
                    message: data.message,
                    isSystemMsg: true
                };
                this.messages.push(msg);
            }

            this.signal_chatAction.dispatch({action: "leave", message: msg});
        });

        this.socket.on('post:parlay', (msg) => {
            const player = this.players.find(player => player.id === msg.userId);

            console.log('GET post:parlay >>> ', msg);

            if(Number.parseInt(msg.postLevel) == 2) {
                this.core.openMessageModal(msg.message);

            } else if (Number.parseInt(msg.postLevel) == 3) {
                msg = {
                    alias: msg.alias,
                    message: msg.message,
                    userId: msg.userId,
                    isChatMaster: player.isModerator,
                    isSystemMsg: true,
                    bannedWord: true,
                };
                this.messages.push(msg);
                this.signal_chatAction.dispatch({action: "post", message: msg});

            } else {
                msg = {
                    alias: msg.alias,
                    message: msg.message,
                    userId: msg.userId,
                    isChatMaster: player.isModerator,
                    isSystemMsg: (Number.parseInt(msg.postLevel) !== 1)
                };
                this.messages.push(msg);
                this.signal_chatAction.dispatch({action: "post", message: msg});
            }
        });

        // this.on('send:message', this.event_sendMessage);
    }

    sendMessage(msg) {
        this.socket.emit('message:parlay', {
            message: msg
        });
    }
    _getRoomIdOrFindDefault(roomId) {
        let currRoomId = roomId;

        if(!roomId) {
            if (this.currentRoom) {
                currRoomId = this.currentRoom;

            } else {
                // No room set as defualt so pick first
                if(_.size(this.rooms) > 0) {
                    this.rooms[0].isOpen = true;
                    currRoomId = this.rooms[0].id;

                } else {
                    throw new Error("chat: No rooms available to open");
                }
            }
        }

        return currRoomId;
    }

    changeRoom(roomId) {
        if(this.initialized) {
            this.socket.emit('join:parlay', this._getRoomIdOrFindDefault(roomId));
        }
    }

    leaveRoom(roomId) {
        if(this.initialized) {
            this.socket.emit('leave:parlay', this._getRoomIdOrFindDefault(roomId));
        }
    }

    getCurrentRoom() {
        let roomName = '';
        _.each(this.rooms, function(r) {
            if(r.isOpen) {
                roomName = r.name;
                return;
            }
        });
        return roomName;
    }

    minimizeChat() {
        this.core.chatModal.minimizeChat();
        this.signal_chatAction.dispatch({action: 'minimizeChat'});
        return true;
    }

    maximizeChat() {
        this.core.chatModal.maximizeChat();
        this.signal_chatAction.dispatch({action: 'maximizeChat'});
        return true;
    }

    toggleChat() {
        if(!this.core.chatModal.isMinimized) {
            this.core.chatModal.minimizeChat(true);
            this.signal_chatAction.dispatch({action: 'minimizeChat'});

        } else {
            this.core.chatModal.maximizeChat(true);
            this.signal_chatAction.dispatch({action: 'maximizeChat'});
        }
    }

    static playerSort(players) {
        if(players) {
            players.sort((a, b) => {
                a = a.name.toUpperCase();
                b = b.name.toUpperCase();
                if (a < b) {
                    return -1;
                }
                if (a > b) {
                    return 1;
                }
                return 0; // names must be equal
            });
        }
        return players;
    }
}