function startGame(manifestURL) {

    var params = new URLSearchParams(location.search.slice(1));

    function getJSON(url) {
        return new Promise(function(resolve) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                let result = {};
                try {
                    const response = xhr.responseText;
                    if(response) {
                        result = JSON.parse(xhr.responseText);
                    }
                } catch(error) {
                    console.warn('Parsing the response from ' + url + ' to JSON failed. Defaulting to an empty object');
                    console.warn(error);
                }
                resolve(result);
            };
            xhr.open('GET', url);
            xhr.setRequestHeader('content-type', 'application/json');
            xhr.send();
        });
    }

    function getRevManifest() {
        return getJSON(manifestURL)
            .catch(() => ({}))
            .then(manifest => new RevManifest(manifest));
    }

    function getContentful(config) {
        return (config.CONTENTFUL || {})[params.get('SITEID')] || {};
    }

    function getLinks(config) {
        return (config.links || {})[params.get('SITEID')] || {};
    }

    function getSiteData(config) {
        var contentful = getContentful(config);
        var links = getLinks(config);
        return {
            sentryDsn: config.SENTRY_DSN ? config.SENTRY_DSN : null,
            sentryTrace: config.SENTRY_TRACING ? config.SENTRY_TRACING : null,
            autoBuyWagerLimitsDisable: config.AUTOBUY_WAGER_LIMITS_DISABLE === "true" ? config.AUTOBUY_WAGER_LIMITS_DISABLE : null,
            sideGamesAssetsURL: config.CONFIG_SIDE_GAME_ASSET_URL ? config.CONFIG_SIDE_GAME_ASSET_URL : null,
            helpURL: links.helpURL,
            responsibleURL: links.responsibleURL,
            ecommURL: links.ecommURL,
            contentfulAccessToken: contentful.CONTENTFUL_MARKETING_TOKEN || null,
            contentfulEnvironmentId: contentful.CONTENTFUL_MARKETING_ENV_ID || null,
            contentfulSpaceId: contentful.CONTENTFUL_MARKETING_SPACE_ID || null
        };
    }

    Promise.all([
        getJSON('/backend-config' + location.search),
        getJSON('/site-data').then(getSiteData),
        getRevManifest()
    ]).then(function(values) {

        const [config, siteData, revManifest] = values;
        const language = params.get('LANGUAGE');

        window.ENV = config.ENV;

        document.body.setAttribute('lang', language);

        // --------------------------------------

        let integrations = [];

        if(siteData.sentryDsn && siteData.sentryTrace !== undefined && siteData.sentryTrace !== null) {
            integrations.push(new Sentry.Integrations.BrowserTracing());
        }

        Sentry.init({
            dsn: siteData.sentryDsn,
            release: VERSION,
            environment: ENV,
            integrations: integrations,
            // We recommend adjusting this value in production, or using tracesSampler for finer control
            tracesSampleRate: siteData.sentryTrace,
        });

        let noSleep = new NoSleep();

        function enableNoSleep() {
            // Wake lock seems to be somewhat problematic based on device and browser, so handle and send for analysis
            try {
                noSleep.enable();
            } catch(err) {
                Sentry.captureException(err);
            }        
            document.removeEventListener('touchstart', enableNoSleep, false);
        }

        // Enable wake lock (must be wrapped in a user input event handler e.g. a mouse or touch handler)
        document.addEventListener('touchstart', enableNoSleep, false);

        WebFont.load({
            custom: {
                families: ['Dimbo'],
                urls: [revManifest.getURL('fonts/fonts.css')]
            },
            timeout: 3000
        });

        //var pixelRatio = (window.devicePixelRatio <= 2 ? window.devicePixelRatio : 2);
        let pixelRatio = 2,
            gameWidth = 1024,
            gameHeight = 672,
            isMobile = ParlayBingo.queryString().MOBILE,
            viewType = (isMobile !== undefined && isMobile === 'yes') ? 'compact' : 'default';

        // Fix blur for Moto G 2nd Gen V5
        if (viewType === 'default' && window.innerWidth === 598 && window.innerHeight === 279 &&
            window.screen.width === 640 && window.screen.height === 360) {
            pixelRatio = window.devicePixelRatio;
        }

        if (viewType === 'compact') {
            gameWidth = 320;
            // Forcing height to always be greater than width (aka Portrait mode)
            if (window.innerHeight > window.innerWidth && window.innerHeight < 1024) {
                gameHeight = window.innerHeight * (gameWidth / window.innerWidth);
            } else if (window.innerHeight > window.innerWidth && window.innerHeight >= 1024) { // added iPad device
                gameHeight = 568.8888888888889;
            } else {
                gameHeight = window.innerWidth * (gameWidth / window.innerHeight);
            }
        }

        this.game = new Phaser.Game({
            width: gameWidth * pixelRatio,
            height: gameHeight * pixelRatio,
            renderer: Phaser.CANVAS,
            parent: 'parlay-bingo',
            antialias: true
        });

        this.game.parlay = new ParlayBingo(
            this.game,
            '',
            config.longLaunchParams,
            language,
            viewType,
            pixelRatio,
            config.sideGames,
            siteData,
            revManifest
        );

        this.game.state.add('bootstrap', PhaserNS.Bootstrap);
        this.game.state.start('bootstrap', true, false);
    });
}
